<template>
    <el-row :gutter="10" align="middle" type="flex" justify="center">
        <el-col :span="8">
            <div style="margin-bottom: 20px">{{ types[type] }} {{ number }}</div>
        </el-col>
        <el-col>
            <el-form-item
                :key="`length-${index}-${length}`"
                :prop="`parcels[${index}].length`"
                :rules="[
                    { required: true, message: 'Lungimea este obligatorie', trigger: 'blur' },
                    { type: 'number', message: 'Lungimea trebuie sa fie un numar' },
                    { validator: validateMax(300) },
                    { validator: validateMin(0.01) },
                ]"
            >
                <el-input v-model.number="length">
                    <template slot="prepend">Lungime</template>
                    <template slot="append">cm</template>
                </el-input>
            </el-form-item>
        </el-col>
        <el-col>
            <el-form-item
                :key="`width-${index}-${width}`"
                :prop="`parcels[${index}].width`"
                :rules="[
                    { required: true, message: 'Latimea este obligatorie', trigger: 'blur' },
                    { type: 'number', message: 'Latimea trebuie sa fie un numar' },
                    { validator: validateMax(300) },
                    { validator: validateMin(0.01) },
                ]"
            >
                <el-input v-model.number="width">
                    <template slot="prepend">Latime</template>
                    <template slot="append">cm</template>
                </el-input>
            </el-form-item>
        </el-col>
        <el-col>
            <el-form-item
                :key="`height-${index}-${height}`"
                :prop="`parcels[${index}].height`"
                :rules="[
                    { required: true, message: 'Inaltimea este obligatorie', trigger: 'blur' },
                    { type: 'number', message: 'Inaltimea trebuie sa fie un numar' },
                    { validator: validateMax(300) },
                    { validator: validateMin(0.01) },
                ]"
            >
                <el-input v-model.number="height">
                    <template slot="prepend">Inaltime</template>
                    <template slot="append">cm</template>
                </el-input>
            </el-form-item>
        </el-col>
        <el-col>
            <el-form-item
                :key="`weight-${index}-${weight}`"
                :prop="`parcels[${index}].weight`"
                :rules="[
                    { required: true, message: 'Greutatea este obligatorie', trigger: 'blur' },
                    { type: 'number', message: 'Greutatea trebuie sa fie un numar' },
                    { validator: validateMax(300) },
                    { validator: validateMin(0.01) },
                ]"
            >
                <el-input v-model.number="weight">
                    <template slot="prepend">Greutate</template>
                    <template slot="append">kg</template>
                </el-input>
            </el-form-item>
        </el-col>
    </el-row>
</template>

<script>
import { sync, call, get } from 'vuex-pathify';

export default {
    props: {
        index: {
            required: true,
        },
    },
    data() {
        return {
            types: {
                0: 'Plic',
                1: 'Pachet',
            },
        };
    },
    methods: {
        validateMax(max) {
            return (rule, value, callback) => {
                if (value > max) {
                    callback(new Error(`Valoarea trebuie sa fie mai mica de ${max}`));
                } else {
                    callback();
                }
            };
        },
        validateMin(min) {
            return (rule, value, callback) => {
                if (value < min) {
                    callback(new Error(`Valoarea trebuie sa fie mai mare de ${min}`));
                } else {
                    callback();
                }
            };
        },
        emitValidation(field) {
            this.$emit('request-validation', `parcels[${this.index}].${field}`);
        },
        onFieldChange(field) {
            // Delay validation to give Vue time to update the model
            this.$nextTick(() => {
                this.$emit('request-validation', `parcels[${this.index}].${field}`);
            });
        },
    },
    computed: {
        ...sync('sales/order@awb.form.parcels[:index]', {
            type: 'type',
            length: 'length',
            width: 'width',
            height: 'height',
            weight: 'weight',
            number: 'number',
        }),
    },
};
</script>
