var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-row',{attrs:{"gutter":10,"align":"middle","type":"flex","justify":"center"}},[_c('el-col',{attrs:{"span":8}},[_c('div',{staticStyle:{"margin-bottom":"20px"}},[_vm._v(_vm._s(_vm.types[_vm.type])+" "+_vm._s(_vm.number))])]),_c('el-col',[_c('el-form-item',{key:`length-${_vm.index}-${_vm.length}`,attrs:{"prop":`parcels[${_vm.index}].length`,"rules":[
                { required: true, message: 'Lungimea este obligatorie', trigger: 'blur' },
                { type: 'number', message: 'Lungimea trebuie sa fie un numar' },
                { validator: _vm.validateMax(300) },
                { validator: _vm.validateMin(0.01) },
            ]}},[_c('el-input',{model:{value:(_vm.length),callback:function ($$v) {_vm.length=_vm._n($$v)},expression:"length"}},[_c('template',{slot:"prepend"},[_vm._v("Lungime")]),_c('template',{slot:"append"},[_vm._v("cm")])],2)],1)],1),_c('el-col',[_c('el-form-item',{key:`width-${_vm.index}-${_vm.width}`,attrs:{"prop":`parcels[${_vm.index}].width`,"rules":[
                { required: true, message: 'Latimea este obligatorie', trigger: 'blur' },
                { type: 'number', message: 'Latimea trebuie sa fie un numar' },
                { validator: _vm.validateMax(300) },
                { validator: _vm.validateMin(0.01) },
            ]}},[_c('el-input',{model:{value:(_vm.width),callback:function ($$v) {_vm.width=_vm._n($$v)},expression:"width"}},[_c('template',{slot:"prepend"},[_vm._v("Latime")]),_c('template',{slot:"append"},[_vm._v("cm")])],2)],1)],1),_c('el-col',[_c('el-form-item',{key:`height-${_vm.index}-${_vm.height}`,attrs:{"prop":`parcels[${_vm.index}].height`,"rules":[
                { required: true, message: 'Inaltimea este obligatorie', trigger: 'blur' },
                { type: 'number', message: 'Inaltimea trebuie sa fie un numar' },
                { validator: _vm.validateMax(300) },
                { validator: _vm.validateMin(0.01) },
            ]}},[_c('el-input',{model:{value:(_vm.height),callback:function ($$v) {_vm.height=_vm._n($$v)},expression:"height"}},[_c('template',{slot:"prepend"},[_vm._v("Inaltime")]),_c('template',{slot:"append"},[_vm._v("cm")])],2)],1)],1),_c('el-col',[_c('el-form-item',{key:`weight-${_vm.index}-${_vm.weight}`,attrs:{"prop":`parcels[${_vm.index}].weight`,"rules":[
                { required: true, message: 'Greutatea este obligatorie', trigger: 'blur' },
                { type: 'number', message: 'Greutatea trebuie sa fie un numar' },
                { validator: _vm.validateMax(300) },
                { validator: _vm.validateMin(0.01) },
            ]}},[_c('el-input',{model:{value:(_vm.weight),callback:function ($$v) {_vm.weight=_vm._n($$v)},expression:"weight"}},[_c('template',{slot:"prepend"},[_vm._v("Greutate")]),_c('template',{slot:"append"},[_vm._v("kg")])],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }